<template>
	<div class="header-wrap" ref="header">
		<header class="header" :class="{ 'header--mobile-active': isMobileMenuOpen }">
			<div class="container header__container">
				<router-link
					to="/"
					class="header__logo logo-link"
				>
					<img src="@/assets/images/landing/logo.svg" class="logo-link__img" alt="DexNet">
				</router-link>

				<nav class="header__nav">
					<ul class="header__nav-list">
						<li class="header__nav-item">
							<router-link to="/" class="header__nav-link">
								{{ $t('Home') }}
							</router-link>
						</li>

						<li class="header__nav-item">
							<router-link to="/dexnode" class="header__nav-link">
								DexNode
							</router-link>
						</li>

						<li class="header__nav-item">
							<a href="/#services" class="header__nav-link">
								{{ $t('Services') }}
							</a>
						</li>

						<li class="header__nav-item">
							<router-link to="/about" class="header__nav-link">
								{{ $t('About') }}
							</router-link>
						</li>
					</ul>
				</nav>

				<div class="header__buttons">
					<div class="languages-list header__languages">
						<p class="languages-list__current">
							{{ currentLocaleTitle }}
						</p>
						<ul v-if="showLngList" class="languages-list__items languages-list__items_position_bottom">
							<li v-for="locale in locales" :key="`locale-${locale.code}`" class="languages-list__item"
								:class="{ 'languages-list__item_current': locale.code === $root.$i18n.locale }"
								@click.prevent="switchLocalePath(locale.code)">
								{{ locale.title }}
							</li>
						</ul>
					</div>

					<button class="header__btn-sign button" @click="showPopupLogin = true">
						<span>
							{{ $t('Sign In') }}
						</span>
					</button>
				</div>

				<div class="menu-btn" :class="{ 'active': isMobileMenuOpen }" id="menu-btn"
					@click="isMobileMenuOpen = !isMobileMenuOpen">
					<div class="menu-btn__burger"></div>
				</div>
			</div>

			<div
				class="header__mobile-content container"
				:class="{ 'header__mobile-content--active': isMobileMenuOpen }"
			>
				<nav class="aside-page__nav">
					<ul class="aside-page__nav-list" @click="closeMobileMenu">
						<li class="aside-page__nav-item">
							<router-link to="/" class="aside-page__nav-link">
								{{ $t('Home') }}
							</router-link>
						</li>
						<li class="aside-page__nav-item">
							<router-link to="/dexnode" class="aside-page__nav-link">
								DexNode
							</router-link>
						</li>
						<li class="aside-page__nav-item">
							<a href="/#services" class="aside-page__nav-link">
								{{ $t('Services') }}
							</a>
						</li>
						<li class="aside-page__nav-item">
							<router-link to="/about" class="aside-page__nav-link">
								{{ $t('About') }}
							</router-link>
						</li>
					</ul>
				</nav>
				<div class="aside-page__footer">
					<div ref="lngList" class="languages-list languages-list-mobile aside-page__languages">

						<p class="languages-list__current">
							{{ currentLocaleTitle }}
						</p>

						<ul v-if="showLngList" class="languages-list__items languages-list__items_position_up">
							<li v-for="locale in locales" :key="`locale-${locale.code}`" class="languages-list__item"
								:class="{ 'languages-list__item_current': locale.code === $root.$i18n.locale }"
								@click.prevent="switchLocalePath(locale.code)">
								{{ locale.title }}
							</li>
						</ul>
					</div>

					<button class="aside-page__btn-sign button" type="button" @click="showPopupLogin = true">
						{{ $t('Sign In') }}
					</button>
				</div>
			</div>
		</header>

		<LndPopupLogin
			v-if="showPopupLogin"
			@close="showPopupLogin = false"
			@showPopupResetPassword="showPopupResetPassword = true"
		/>

		<LndPopupResetPassword
			v-if="showPopupResetPassword"
			@close="showPopupResetPassword = false"
		/>
	</div>
</template>

<script>
import anime from 'animejs'

import LndPopupLogin from '@/components/lnd/PopupLogin.vue'
import LndPopupResetPassword from '@/components/lnd/PopupResetPassword.vue'

export default {
	name: 'LndHeader',
	components: {
		LndPopupLogin,
		LndPopupResetPassword,
	},
	data: () => ({
		showLngList: true,
		locales: [
			{ code: 'en', iso: 'en-US', file: 'en.json', title: 'English' },
			{ code: 'ru', iso: 'ru-EU', file: 'ru.json', title: 'Русский' },
		],

		isMobileMenuOpen: false,

		showPopupLogin: false,
		showPopupResetPassword: false,
	}),
	watch: {
		isMobileMenuOpen(val) {
			if (val) {
				document.documentElement.classList.add('overflow-hidden')
			} else {
				document.documentElement.classList.remove('overflow-hidden')
			}
		},
	},
	computed: {
		currentLocaleTitle() {
			return this.locales.find(
				(locale) => locale.code === this.$root.$i18n.locale
			)?.title
		},
	},
	methods: {
		async switchLocalePath(locale) {
			this.$root.$i18n.locale = locale
			localStorage.setItem('locale', locale)

			this.showLngList = false
			setTimeout(() => {
				this.showLngList = true
			}, 300)
		},
		closeMobileMenu() {
			this.isMobileMenuOpen = false
		},
	},
	mounted() {
		setTimeout(() => {
			if (window.location.hash) {
				const hash = window.location.hash.slice(1)
				const el = document.getElementById(hash)
				if (el) {
					el.scrollIntoView({ behavior: 'smooth' })
				}
			}
		}, 500)

		// Animations

		const { matches: isMobile } = window.matchMedia("(max-width: 768px)");

		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (!entry.isIntersecting) {
					return
				}

				// When the element comes into view, start the animation

				// Left top header
				anime({
					targets: '.header__nav-list li',
					translateX: [ '-25px', '0px' ], // Animate from -100px to 0px
					opacity: [ 0, 1 ],               // Fade in opacity
					delay: anime.stagger(150, { start: 0, direction: 'reverse' }),       // Delay each animation by 200ms
					easing: 'spring(1, 55, 8, 1)',           // Easing for smooth animation
					duration: 150                   // Duration for each element's animation
				});

				anime({
					targets: '.header__logo',
					translateX: [ '-25px', '0px' ], // Animate from -100px to 0px
					opacity: [ 0, 1 ],               // Fade in opacity
					delay: isMobile ? 0 : 600,
					easing: 'spring(1, 55, 8, 1)',           // Easing for smooth animation
				});

				// Right top header

				if (isMobile) {
					anime({
						targets: '.menu-btn',
						translateX: [ '25px', '0px' ], // Animate from -100px to 0px
						opacity: [ 0, 1 ],               // Fade in opacity
						delay: 0,
						easing: 'spring(1, 55, 8, 1)',           // Easing for smooth animation
					});
				} else {
					anime({
						targets: '.header__buttons > *',
						translateX: [ '25px', '0px' ], // Animate from -100px to 0px
						opacity: [ 0, 1 ],               // Fade in opacity
						delay: anime.stagger(150, { start: 150 }),       // Delay each animation by 200ms
						easing: 'spring(1, 55, 8, 1)',           // Easing for smooth animation
						duration: 150                   // Duration for each element's animation
					});
				}

				observer.unobserve(entry.target); // Stop observing once animation starts
			});
		}, { threshold: 0.5 }); // Adjust threshold as needed

		// Target the block for observation
		observer.observe(this.$refs.header);
	},
	beforeDestroy() {
		document.documentElement.classList.remove('overflow-hidden')
	},
}
</script>
<style scoped>
.header__logo {
	opacity: 0;
}

.header-wrap {
	width: 100%;
	min-height: 78px;

	position: sticky;
	top: 0;
	left: 0;
	z-index: 99999;
}

.header-wrap {
	transition: all 0.6s linear !important;

	width: 100%;
	height: 78px;
}

.header {
	transition: all 0.6s linear !important;
	/* background-color: var(--root-color) !important; */
	background-color: #000 !important;

	position: sticky !important;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
	height: 78px;
}

.header--mobile-active {
	height: 100svh;
	overflow: hidden;

	backdrop-filter: blur(40px);
}

.header.header--mobile-active {
	background-color: rgba(4, 11, 11, 0.8) !important;
}

.header__mobile-content {
	height: 0px !important;
	overflow: hidden;
	/* transition: all 0.3s cubic-bezier(1,.05,.84,.24) !important; */
	/* transition: all 0.6s cubic-bezier(1,.05,.84,.24) !important; */
	transition: all 0.6s cubic-bezier(.71, -0.04, .91, .43) !important;
	filter: blur(20px);
	margin-top: 20px;
}

.header__mobile-content--active {
	height: 300px !important;
	filter: blur(0);
}

.menu-btn {
	position: relative;
	width: 35px;
	height: 35px;
	cursor: pointer;
	opacity: 0;
}

@media (min-width: 768px) {
	.menu-btn {
		display: none;
	}
}

.menu-btn__burger,
.menu-btn__burger::before,
.menu-btn__burger::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 3px;
	background-color: white;
	transition: all 0.3s ease-in-out;
	border-radius: 2px;
}

.menu-btn__burger {
	top: 50%;
	transform: translateY(-50%);
}

.menu-btn__burger::before {
	top: -10px;
}

.menu-btn__burger::after {
	top: 10px;
}

.menu-btn.active .menu-btn__burger {
	background-color: transparent;
}

.menu-btn.active .menu-btn__burger::before {
	top: 0;
	transform: rotate(45deg);
}

.menu-btn.active .menu-btn__burger::after {
	top: 0;
	transform: rotate(135deg);
}

.languages-list {
	opacity: 0;
}

.languages-list-mobile {
	opacity: 1 !important;
}

.header__btn-sign {
	opacity: 0;
}
</style>